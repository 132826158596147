import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import '@/assets/css/flex.css'
import '@/assets/font/font.css'


// import 'lib-flexible'

import './utils/rem.js'


new Vue({
  render: h => h(App),
}).$mount('#app')
