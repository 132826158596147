<template>
  <div class="speedContent">
    <!-- 背景一 -->
    <div class="bg1 flex_center">
      <div class="contentbox">
        <titlePage
          @tab="tab"
          :tabIndex="tabIndex"
        ></titlePage>

        <div style="width:100%;text-align: center">
          <img
          src="@/assets/image/font.png"
          class="font1"
        />
        </div>  
        
        <div style="width:100%;text-align: center">
          <img
          src="@/assets/image/down_window.png"
          class="down_window"  @click="downLoad"
        />
        </div>
        

        <div class="mouse">
          <img
            src="@/assets/image/mouse.png"
            class="mouse_img"
          />
          <div class="mouse_title">滚动查看更多</div>
        </div>

      </div>

    </div>
    <!-- 背景二 -->
    <div class="bg2 flex_center">
      <div class="contentbox">
        <div class="bg2Title flex_star_nowrap">
          <div>
            <span>真正的</span>
            <!-- <span class="begin">云游</span> -->
            <!-- <span>你的</span> -->
            <img
              src="@/assets/image/font8.png"
              class="img"
            />
          </div>
          <img
            src="@/assets/image/font_1.png"
            class="bottom"
          />
        </div>

        <div class="center_describe">
          <div>自研智能游戏线路匹配加速技术，覆盖了5万+条游戏专</div>
          <div>网专线节点，确保用户在全球任意地区玩任何游戏区服</div>
          <div>都体验最高稳定性，享受军工级超低延迟体验。</div>
          <div class="img">
            <img
              src="@/assets/image/ellipse.png"
              class="ellipse margin_right4"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_left4"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_left4"
            />

          </div>
        </div>

        <div class="mouse">
          <img
            src="@/assets/image/mouse.png"
            class="mouse_img"
          />
          <div class="mouse_title">滚动查看更多</div>
        </div>

      </div>

    </div>
    <!-- 背景三 -->
    <div class="bg3 flex_center">
      <div class="contentbox">
        <div class="bg2Title flex_end_nowrap">
          <div>
            <!-- <span class="begin">云游</span> -->
            <!-- <span>你的</span> -->
            <img
              src="@/assets/image/font5.png"
              class="img"
            />
            <span>
              ，就够了
            </span>
          </div>
          <img
            src="@/assets/image/font_2.png"
            class="bottom"
          />
        </div>

        <div class="center_describe flex_end_wrap">
          <div style="width:100%;">支持全平台加速覆盖99%游戏——再小众的游戏也</div>
          <div style="width:100%;">找得到。</div>
          <!-- <div>都体验最高稳定性，享受军工级超低延迟体验。</div> -->
          <div class="img">

            <img
              src="@/assets/image/cricle.png"
              class="cricle"
            />
            <img
              src="@/assets/image/ellipse.png"
              class="ellipse margin_left4"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_left4"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_left4"
              style="margin-right: 35px;"
            />

          </div>
        </div>

        <div class="mouse">
          <img
            src="@/assets/image/mouse.png"
            class="mouse_img"
          />
          <div class="mouse_title">滚动查看更多</div>
        </div>

      </div>

    </div>
    <!-- 背景四 -->
    <div
      class="bg4 flex_center"
      style="margin-top:-3px;"
    >
      <div class="contentbox">
        <div class="bg2Title flex_star_nowrap">
          <div>
            <span>丰富的游戏资源</span>
          </div>
          <img
            src="@/assets/image/font_3.png"
            class="bottom"
          />
        </div>

        <div
          class="center_describe"
          style="margin-bottom:77px;"
        >
          <div>完美支持市场全品类游戏、Steam游戏库</div>
          <div
            class="img"
            style="margin-top:115px;"
          >
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_right4"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_right4"
            />
            <img
              src="@/assets/image/ellipse.png"
              class="ellipse margin_right4"
            />

            <img
              src="@/assets/image/cricle.png"
              class="cricle"
            />

          </div>
        </div>

        <img
          src="@/assets/image/font4.png"
          style="width:757px;height:78px;margin-bottom: 66px;"
        />

        <div class="mouse">
          <img
            src="@/assets/image/mouse.png"
            class="mouse_img"
          />
          <div class="mouse_title">滚动查看更多</div>
        </div>

      </div>
    </div>
    <!-- 背景五 -->
    <div class="bg5 flex_center">
      <div class="contentbox">
        <div class="bg2Title flex_end_nowrap">
          <div>
            <span>
              极致的用户体验
            </span>
          </div>
          <img
            src="@/assets/image/font_4.png"
            class="bottom"
          />
        </div>

        <div class="center_describe flex_end_wrap">
          <div style="width:100%;">一键加速、一键暂停、自动选区......</div>
          <div style="width:100%;">所有功能一目了然，轻松操作。</div>
          <!-- <div>都体验最高稳定性，享受军工级超低延迟体验。</div> -->
          <div class="img">

            <img
              src="@/assets/image/cricle.png"
              class="cricle"
            />

            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_left4"
            />
            <img
              src="@/assets/image/cricle.png"
              class="cricle margin_left4"
            /> <img
              src="@/assets/image/ellipse.png"
              class="ellipse margin_left4"
              style="margin-right: 35px;"
            />

          </div>
        </div>

        <div class="mouse">
          <img
            src="@/assets/image/mouse.png"
            class="mouse_img"
          />
          <div class="mouse_title">滚动查看更多</div>
        </div>

      </div>
    </div>

    <!-- 背景六 -->
    <div class="bg6 flex_center">
      <div class="contentbox">

        <div class="title_6">持续更新，优化不断......</div>
        <div class="c">每一次更新都是为了让你的游戏体验更加完美</div>
      </div>
    </div>

  </div>
</template>

<script>
import titlePage from '@/components/title.vue'
export default {
  props: ['tabIndex'],
  components: {
    titlePage
  },
  methods: {
    tab(index) {
      // this.tabIndex = index;
      this.$emit('tab', index);
    },
    downLoad(){
      console.log("下载-----");
      let a=document.createElement("a");
      a.setAttribute("href","http://speeder.yunpxe.com/云游加速器.exe");
      a.setAttribute("download","云游加速器.exe");
      a.click();
      document.body.removeChild(a);

    }
  }
}
</script>
<style lang="less">
.mouse {
  width: 100%;
  text-align: center;
  .mouse_img {
    width: 16px;
    height: 24px;
  }
  margin-bottom: 10px;
  .mouse_title {
    font-size: 14px;
  }
}
.margin_right4 {
  margin-right: 4px;
}
.margin_left4 {
  margin-left: 4px;
}
.center_describe {
  font-size: 24px;
  margin-bottom: 214px;
  .img {
    margin-top: 49px;
    .ellipse {
      width: 64px;
      height: 8px;
    }
    .cricle {
      width: 8px;
      height: 8px;
    }
  }
}
.contentbox {
  width: calc(100% - 360px - 360px);
}
.bg2Title {
  position: relative;
  font-size: 48px;
  font-family: "PingFang-Medium";

  height: 221px;
  .begin {
    margin-right: 35px;
    display: inline-block;
  }
  .img {
    width: 172px;
    height: 46px;
    margin-bottom: -6px;
  }
  .bottom {
    position: absolute;
    z-index: 2;
    width: 333px;
    height: 221px;
    left: 0px;
  }
}
</style>

<style lang="less" scoped>
.speedContent {
  color: #fff;
  min-width: 1920px;
  // height: 5477px;
  background: #0a1b3d;
  .bg1 {
    width: 100%;
    min-height: 952px;
    background: url("@/assets/image/bg3.png");
    // background: url("../assets/image/bg3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .contentbox {
      text-align: center;
      .font1 {
        //width: 864px;
        //height: 203px;
        width:845px;
        height:159px;
        margin-top: 240px;
        margin-bottom: 91px;
      }
      .down_window {
        width: 434px;
        height: 122px;
        margin-bottom: 138px;
        cursor:pointer;
      }
    }
  }
  .bg2 {
    width: 100%;
    min-height: 960px;
    background: url("@/assets/image/bg4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .contentbox {
      padding-top: 274px;
      .bg2Title {
        .bottom {
          position: absolute;
          z-index: 2;
          width: 256px;
          left: 0px;
        }
      }
    }
  }
  .bg3 {
    margin-top: 0px;
    width: 100%;
    min-height: 963px;
    background: url("@/assets/image/bg5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .contentbox {
      padding-top: 274px;
      .bg2Title {
        .img {
          width: 237px !important;
        }
        .bottom {
          position: absolute;
          z-index: 2;
          width: 325px;
          right: 0px;
          left: auto;
        }
      }
      .center_describe {
        text-align: right;
      }
    }
  }
  .bg4 {
    width: 100%;
    min-height: 960px;
    background: url("@/assets/image/bg6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .contentbox {
      padding-top: 274px;
      .bg2Title {
        .bottom {
          position: absolute;
          z-index: 2;
          width: 333px;
          height: 221px;
          left: 0px;
        }
      }
    }
  }
  .bg5 {
    width: 100%;
    min-height: 960px;
    background: url("@/assets/image/bg7.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .contentbox {
      padding-top: 274px;
      .bg2Title {
        .img {
          width: 237px !important;
        }
        .bottom {
          position: absolute;
          z-index: 2;
          width: 344px;
          right: 0px;
          left: auto;
        }
      }
      .center_describe {
        text-align: right;
      }
    }
  }
  .bg6 {
    width: 100%;
    min-height: 950px;
    background: url("@/assets/image/bg9.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: -157px;
    color: #000;
    .title_6 {
      width:100%;
      text-align: left;
      margin-top: calc(94px + 157px);
      font-size: 48px;
      margin-bottom: 40px;
    }
    .describe_6 {
      font-size: 24px;
    }
  }
}
</style>