<template>
  <div>
    
    <!-- <div
      class="speedContent"
      v-if="tabIndex===2"
    > -->
    <indexPage v-if="tabIndex===1" @tab="tab" :tabIndex="tabIndex"/>
    <speedPage v-if="tabIndex===2" @tab="tab" :tabIndex="tabIndex"/>

    <!-- </div> -->
  </div>
</template>

<script>
import indexPage from '@/components/index.vue'
import speedPage from '@/components/speed.vue'
// import titlePage from '@/components/title.vue'
export default {
  name: 'App',
  components: {
    indexPage, speedPage,
    //  titlePage
  },
  data() {
    return {
      tabIndex: 1, //默认显示首页 取值1,2
    }
  },
  methods: {
    tab(index) {
      this.tabIndex = index;
    }
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "PingFang-Medium";
}
</style>

<!-- <style lang="less">
.indexContent {
  width: 1920px;
  height: 2019px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .bg1 {
    width: 100%;
    height: 939px;
    background: url("@/assets/image/bg1.png");

    .contentbox {
      width: calc(100% - 360px - 360px);
    }
  }
  .bg2 {
    width: 100%;
    height: 1080px;
    background: url("@/assets/image/bg2.png");
  }
}
.speedContent {
  width: 1920px;
  height: 5477px;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  .bg1 {
    width: 100%;
    height: 952px;
    background: url("@/assets/image/bg3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .contentbox {
      width: calc(100% - 360px - 360px);
    }
  }
  .bg2 {
    width: 100%;
    height: 960px;
    background: url("@/assets/image/bg4.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bg3 {
    margin-top:0px;
    width: 100%;
    height: 963px;
    background: url("@/assets/image/bg5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bg4 {
    width: 100%;
    height: 960px;
    background: url("@/assets/image/bg6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .bg5 {
    width: 100%;
    height: 960px;
    background: url("@/assets/image/bg7.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style> -->

