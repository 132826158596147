var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"speedContent"},[_c('div',{staticClass:"bg1 flex_center"},[_c('div',{staticClass:"contentbox"},[_c('titlePage',{attrs:{"tabIndex":_vm.tabIndex},on:{"tab":_vm.tab}}),_vm._m(0),_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('img',{staticClass:"down_window",attrs:{"src":require("@/assets/image/down_window.png")},on:{"click":_vm.downLoad}})]),_vm._m(1)],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('img',{staticClass:"font1",attrs:{"src":require("@/assets/image/font.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mouse"},[_c('img',{staticClass:"mouse_img",attrs:{"src":require("@/assets/image/mouse.png")}}),_c('div',{staticClass:"mouse_title"},[_vm._v("滚动查看更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg2 flex_center"},[_c('div',{staticClass:"contentbox"},[_c('div',{staticClass:"bg2Title flex_star_nowrap"},[_c('div',[_c('span',[_vm._v("真正的")]),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/font8.png")}})]),_c('img',{staticClass:"bottom",attrs:{"src":require("@/assets/image/font_1.png")}})]),_c('div',{staticClass:"center_describe"},[_c('div',[_vm._v("自研智能游戏线路匹配加速技术，覆盖了5万+条游戏专")]),_c('div',[_vm._v("网专线节点，确保用户在全球任意地区玩任何游戏区服")]),_c('div',[_vm._v("都体验最高稳定性，享受军工级超低延迟体验。")]),_c('div',{staticClass:"img"},[_c('img',{staticClass:"ellipse margin_right4",attrs:{"src":require("@/assets/image/ellipse.png")}}),_c('img',{staticClass:"cricle",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"cricle margin_left4",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"cricle margin_left4",attrs:{"src":require("@/assets/image/cricle.png")}})])]),_c('div',{staticClass:"mouse"},[_c('img',{staticClass:"mouse_img",attrs:{"src":require("@/assets/image/mouse.png")}}),_c('div',{staticClass:"mouse_title"},[_vm._v("滚动查看更多")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg3 flex_center"},[_c('div',{staticClass:"contentbox"},[_c('div',{staticClass:"bg2Title flex_end_nowrap"},[_c('div',[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/font5.png")}}),_c('span',[_vm._v(" ，就够了 ")])]),_c('img',{staticClass:"bottom",attrs:{"src":require("@/assets/image/font_2.png")}})]),_c('div',{staticClass:"center_describe flex_end_wrap"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v("支持全平台加速覆盖99%游戏——再小众的游戏也")]),_c('div',{staticStyle:{"width":"100%"}},[_vm._v("找得到。")]),_c('div',{staticClass:"img"},[_c('img',{staticClass:"cricle",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"ellipse margin_left4",attrs:{"src":require("@/assets/image/ellipse.png")}}),_c('img',{staticClass:"cricle margin_left4",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"cricle margin_left4",staticStyle:{"margin-right":"35px"},attrs:{"src":require("@/assets/image/cricle.png")}})])]),_c('div',{staticClass:"mouse"},[_c('img',{staticClass:"mouse_img",attrs:{"src":require("@/assets/image/mouse.png")}}),_c('div',{staticClass:"mouse_title"},[_vm._v("滚动查看更多")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg4 flex_center",staticStyle:{"margin-top":"-3px"}},[_c('div',{staticClass:"contentbox"},[_c('div',{staticClass:"bg2Title flex_star_nowrap"},[_c('div',[_c('span',[_vm._v("丰富的游戏资源")])]),_c('img',{staticClass:"bottom",attrs:{"src":require("@/assets/image/font_3.png")}})]),_c('div',{staticClass:"center_describe",staticStyle:{"margin-bottom":"77px"}},[_c('div',[_vm._v("完美支持市场全品类游戏、Steam游戏库")]),_c('div',{staticClass:"img",staticStyle:{"margin-top":"115px"}},[_c('img',{staticClass:"cricle margin_right4",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"cricle margin_right4",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"ellipse margin_right4",attrs:{"src":require("@/assets/image/ellipse.png")}}),_c('img',{staticClass:"cricle",attrs:{"src":require("@/assets/image/cricle.png")}})])]),_c('img',{staticStyle:{"width":"757px","height":"78px","margin-bottom":"66px"},attrs:{"src":require("@/assets/image/font4.png")}}),_c('div',{staticClass:"mouse"},[_c('img',{staticClass:"mouse_img",attrs:{"src":require("@/assets/image/mouse.png")}}),_c('div',{staticClass:"mouse_title"},[_vm._v("滚动查看更多")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg5 flex_center"},[_c('div',{staticClass:"contentbox"},[_c('div',{staticClass:"bg2Title flex_end_nowrap"},[_c('div',[_c('span',[_vm._v(" 极致的用户体验 ")])]),_c('img',{staticClass:"bottom",attrs:{"src":require("@/assets/image/font_4.png")}})]),_c('div',{staticClass:"center_describe flex_end_wrap"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v("一键加速、一键暂停、自动选区......")]),_c('div',{staticStyle:{"width":"100%"}},[_vm._v("所有功能一目了然，轻松操作。")]),_c('div',{staticClass:"img"},[_c('img',{staticClass:"cricle",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"cricle margin_left4",attrs:{"src":require("@/assets/image/cricle.png")}}),_c('img',{staticClass:"cricle margin_left4",attrs:{"src":require("@/assets/image/cricle.png")}}),_vm._v(" "),_c('img',{staticClass:"ellipse margin_left4",staticStyle:{"margin-right":"35px"},attrs:{"src":require("@/assets/image/ellipse.png")}})])]),_c('div',{staticClass:"mouse"},[_c('img',{staticClass:"mouse_img",attrs:{"src":require("@/assets/image/mouse.png")}}),_c('div',{staticClass:"mouse_title"},[_vm._v("滚动查看更多")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg6 flex_center"},[_c('div',{staticClass:"contentbox"},[_c('div',{staticClass:"title_6"},[_vm._v("持续更新，优化不断......")]),_c('div',{staticClass:"c"},[_vm._v("每一次更新都是为了让你的游戏体验更加完美")])])])
}]

export { render, staticRenderFns }