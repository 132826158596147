<!-- 标题组件 -->
<template>
  <div>
    <div class="titlebox">
      <div class="flex_btw_nowrap">
        <div class="flex_star_nowrap">
          <img
            src="@/assets/image/title.png"
            class="titleimg"
          />
          <div class="fontstle flex_clo_center">
            <div
              class="fontstyle"
              @click="tab(1)"
            >首页</div>
            <img
              v-if="tabIndex==1"
              src="@/assets/image/deformation.png"
              class="undelineimg"
            />
          </div>
          <div class="fontstle flex_clo_center">
            <div
              class="fontstyle"  @mouseover="handleMouseOver"
              @click="tab(2)"
            >云游加速器<img
                class="down" 
                src="@/assets/image/down.png"
              /></div>
            <img
              v-if="tabIndex==2"
              src="@/assets/image/deformation.png"
              class="undelineimg"
            />
          </div>
        </div>

      <!--- <img
          src="@/assets/image/download.png"
          class="downloadimg "
        />-->

      </div>
    </div>
    <!-- hover -->
    <div
      class="hoverBox flex_center" @mouseleave="handleMouseLeave"
      v-if="showHover"
      >
      <!-- v-if="tabIndex==2  && showHover" -->

      <div class="box" style="background:#1a242b; ">
        <div class="content flex_star_wrap">
          <img src="@/assets/image/windows.png" class="windows"/>
        
          <div>
            <div class="font14">云游加速器Windows版</div>
            <div class="font12">覆盖Windows全品类游戏，丰富的游戏周边功能，</div>
            <div class="font12">提供一站式服务。</div>
          </div>
        
        </div>
      </div>
      <!-- <div class="box" style="background: linear-gradient(to right,#02080a,#02090b);"> -->
      <div class="box">
        <div class="content flex_star_wrap">
          <img src="@/assets/image/pc.png" class="windows"/>
        
          <div>
            <div class="font14">云游加速器网吧版</div>
            <div class="font12">敬请期待...</div>
          </div>
        
        </div>
      </div>

    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  props: ['tabIndex'],
  data() {
    return {
      showHover:false,
      // tabIndex:1, //默认显示首页 取值1,2
    }
  },
  methods: {
    handleMouseOver(){
      this.showHover=true;
    },
    handleMouseLeave(){
      this.showHover=false;
    },

    tab(index) {
      this.$emit('tab', index)
    }
  }
}
</script>

<style lang="less">
.titlebox {
  margin-top: 14px;
  width: 100%;
  height: 36px;
  color: #fff;
  position: relative;
  z-index: 999;
  .titleimg {
    width: 200px;
    height: 36px;
  }
  .fontstle {
    margin: 0px 40px;
    position: relative;
    .undelineimg {
      width: 28px;
      height: 2px;
      position: absolute;
      bottom: -22px;
    }
    .fontstyle {
      font-size: 14px;
      cursor: pointer;
      .down {
        width: 11px;
        height: 7px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .downloadimg {
    width: 108px;
    height: 34px;
    cursor: pointer;
  }
}

.hoverBox {
  width: 100%;
  height: 340px;
  background: rgba(3, 4, 7, 0.8);
  position: absolute;
  z-index: 66;
  // top:70px;
  top: 0px;
  left: 0;
  // display: none;
  .box {
    width: 600px;
    height: 180px;
    margin-top:112px;
    text-align: left;
    .content {
      margin-top: 54px;
      margin-left: 138px;
      .windows{
        width:29px;
        height: 29px;
        margin-right: 24px;
      }
      .font14{
        font-size:14px;
        line-height: 24px;
      }
      .font12{
        font-size: 12px;
        line-height: 24px;
      }
    }
  }
}

.down:hover {

}
// .hoverBox:hover{
//   display: block;
// }
</style>