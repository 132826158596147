<template>
  <div class="indexContent">
    <!-- 背景一 -->
    <div class="bg1 flex_center">
      <div class="contentbox">
        <titlePage
          @tab="tab"
          :tabIndex="tabIndex"
        ></titlePage>

        <img
          src="@/assets/image/font3.png"
          class="bigtitle"
        />

        <div class="describe">够快/够稳/0卡顿</div>

        <div>
          <img
            src="@/assets/image/btn1.png"
            class="btn1" @click="downLoad()"
          />
          <img
            src="@/assets/image/btn2.png"
            class="btn2"
          />
        </div>

        <div class="describe2">支持全平台加速 覆盖99%游戏 连接全球玩家</div>

      </div>

    </div>
    <!-- 背景二 -->
    <div class="bg2 flex_center">
      <div class="contentbox">
        <div class="bg2Title flex_star_nowrap">
          <div>
            <span class="begin">云游</span>
            <span>你的</span>
            <img
              src="@/assets/image/font7.png"
              class="img"
            />
          </div>
          <img
            src="@/assets/image/font_2.png"
            class="bottom"
          />
        </div>

        <div class="center_describe">
          <div>安全，是云游加速器不变的承诺。采用军工级加密技术，确保</div>
          <div>你的网络数据传输安全无忧，有效防止黑客入侵和个人信息泄</div>
          <div>漏。在这里，你可以放心加速，尽情游戏，无需担心任何网络</div>
          <div>安全问题</div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import titlePage from '@/components/title.vue'
export default {
  props: ['tabIndex'],
  components: {
    titlePage
  },
  methods: {
    tab(index) {
      // this.tabIndex = index;
      this.$emit('tab', index);
    },
    downLoad(){
      console.log("下载-----");
      let a=document.createElement("a");
      a.setAttribute("href","http://speeder.yunpxe.com/云游加速器.exe");
      a.setAttribute("download","云游加速器.exe");
      a.click();
      document.body.removeChild(a);

    }
  }
}
</script>

<style lang="less" scoped>
.indexContent {
  min-width: 1920px;
  min-height: 2019px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  .bg1 {
    width: 100%;
    min-height: 939px;
    background: url("@/assets/image/bg1.png");
    // background: url("../assets/image/bg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .contentbox {
      width: calc(100% - 360px - 360px);
      text-align: center;
      .bigtitle {
        width: 1061px;
        height: 127px;
        margin-top: 142px;
      }
      .describe {
        font-size: 32px;
        margin-top: 40px;
        margin-bottom: 172px;
      }
      .btn1 {
        width: 290px;
        height: 80px;
        cursor:pointer;
      }
      .btn2 {
        width: 220px;
        height: 86px;
      }
      .describe2 {
        font-size: 18px;
        margin-top: 92px;
      }
    }
  }
  .bg2 {
    width: 100%;
    min-height: 1080px;
    background: url("@/assets/image/bg2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .contentbox {
      width: calc(100% - 360px - 360px);
      // text-align: center;
      padding-top: 318px;
      .bg2Title {
        position: relative;
        font-size: 48px;
        font-family: "PingFang-Medium";

        height: 221px;
        .begin {
          margin-right: 35px;
          display: inline-block;
        }
        .img {
          // width: 288px;
          // height: 67px;
          width: 284px;
          height: 45px;
          margin-bottom: -5px;
        }
        .bottom {
          position: absolute;
          z-index: 2;
          width: 325px;
          height: 221px;
          left: 0px;
        }
      }
      .center_describe {
        font-size: 24px;
      }
    }
  }
}
</style>